<template>
    <div>
        <el-dialog
            :visible="show"
            :before-close="handleClose"
            width="390px"
            class="project-modal"
        >
            <el-form
                ref="form"
                :model="formData"
                :rules="rules"
                class="project-form overflow-hidden"
                label-position="top"
            >
                <h3>Select Credit Card</h3>
                <select
                    v-model="formData.card"
                    class="form-select mb-5 custom-input-class"
                    aria-label="Default select example"

                >
                    <option disabled selected>Select a card to pay</option>
                    <option
                        v-for="card in allCard"
                        :value="card.id"
                    >{{ card.billing_details.name }} {{ card.card.brand }} {{ card.card.last4 }}
                    </option>
                </select>

                <div class="d-flex justify-content-end" >
                    <el-button
                        v-if="!formData.card"
                        :loading="loading"
                        type="success"
                        @click.prevent="addNewCard('form')">Add a New Card
                    </el-button>
                    <el-button
                        :loading="loading"
                        type="success"
                        @click="openSummaryModal('form')">Next
                    </el-button>
                </div>

            </el-form>
        </el-dialog>

        <payment-summary-modal
            v-if="plan"
            :show="openModal"
            @close="openModal = false"
            @success="paymentSuccess($event)"
            :plan="plan"
            :membersCount="membersCount"
            :card="formData.card"
            :upgradeOrDowngrade="upgradeOrDowngrade"
            :teamId="teamId"
        />
    </div>
</template>


<script>
import PaymentSummaryModal from "./PaymentSummaryModal";
import {Notification} from "element-ui";

export default {
    name: "SelectCardModal",
    props: ["show", "allCard", "plan", "upgradeOrDowngrade", "teamId", "membersCount"],
    components: {PaymentSummaryModal},
    data() {
        return {
            formData: {
                card: null
            },
            rules: {
                card: [
                    {required: true, message: "Please select a card", trigger: "change"}
                ]
            },
            loading: false,
            openModal: false
        };
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },

        addNewCard() {
            this.$emit('addNewCard');
        },

        openSummaryModal() {
            console.log(this.formData.card);
            if(this.formData.card){
                this.$emit('close');
                this.openModal = true;
            }else{
                this.openModal = false;
                Notification.error('Please select a card or add a card first!');
            }
        },
        paymentSuccess(data) {
            this.$emit('success', data);
        }
    },

};
</script>

<style scoped>
h3 {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #092c4c;
    margin-bottom: 30px;
}

.custom-input-class {
    width: 100%;
    background: #F3F3F3;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 25px;
    font-size: 14px;
}
</style>