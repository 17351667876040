<template>
  <div class="row">
    <div v-for="plan in pricingPlans" :key="plan.id"
         class="col-xl-3 col-md-6 mb-4">
      <div class="single-price-card" :class="{'active': subscribedToThis(plan) }">
        <div
            class="pricing-table-headding">
          <h3 v-if="plan.package === 'yearly'" class="dis_card">10% Discount</h3>
          <h3 v-else-if="plan.package === 'monthly' && plan.name === 'SILVER' || plan.name === 'PREMIUM'" class="dis_card">Preferred</h3>
          <h3 v-else class="dis_blank"></h3>
          <h3 class="pricing-table-title">{{ plan.name }}</h3>
        </div>
        <div
            class="pricing-table-divider">
        </div>
        <div class="pricing-table-price">
          <div
              class="pricing-table-price-inner">
            <sup
                class="pricing-table-currency">$</sup>
            <span
                class="pricing-table-main-price price">{{ plan.price }}</span>
            <sub
                class="pricing-table-preiod per">{{ `/month${ $route.query.submenu === 'team' ? '/member' : ''}`}}</sub>
          </div>
        </div>
        <div
            class="pricing-table-feature">
          <ul>
            <li>First 2 Weeks Free</li>
            <li>{{
                plan.active_project
              }} Active Projects
            </li>
            <li>{{
                plan.storage_limit_in_gb
              }} GB Total Storage
            </li>
            <li>{{ plan.user_limit }}
              Active Collaborators
            </li>
            <li>1 Support Staff
            </li>
          </ul>
        </div>
        <div class="pricing-table-button">
          <el-button
              :disabled="subscribedToThis(plan)"
              @click.prevent="addSubscription(plan)"
              type="success"
              class="price-btn">
            {{ planBtnName(plan) }}
            Plan
          </el-button>
        </div>
        <div class="pricing-upgrade-info">
          <div v-if="subscribedToThis(plan)" class="text-sm text-white text-center">
            {{ getSubscriptionValidity() }}
          </div>
          <div v-if="subscribedToThis(plan) && (currentSubscription && currentSubscription.status === 'active')"
               class="text-center">
            <button
                v-if="(currentSubscription && currentSubscription.ends_at && currentSubscription.status === 'active')"
                @click="resumePlan()"
                class="badge badge-danger-light text-white border-0">
              Resume
            </button>
            <button
                v-if="(currentSubscription && !currentSubscription.ends_at && currentSubscription.status === 'active')"
                @click="cancelPlan()"
                class="badge badge-danger-light text-white border-0">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-md-6 mb-4">
      <div class="single-price-card">
        <div
            class="pricing-table-headding">
          <h3 class="dis_blank"></h3>
          <h3
              class="pricing-table-title">
            {{ $route.query.submenu === 'team' ? 'ENTERPRISE' : 'PLATINUM' }}</h3>
        </div>
        <div
            class="pricing-table-divider">
        </div>
        <div class="pricing-table-text justify-content-center">Looking for more?</div>
        <div class="pricing-table-button">
          <a href="https://longleap.one/#contact" target="_blank" class="el-button price-btn el-button--success">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {Notification} from "element-ui";
import moment from "moment/moment";
export default {
  name: "PricingPlan",
  props: ["currentSubscription", "plans", "loading", "loadingPlan"],
  computed: {
    pricingPlans() {
      return this.plans;
    }
  },
  methods: {
    subscribedToThis(plan) {
      return (this.currentSubscription && plan.stripe_id === this.currentSubscription.stripe_price);
    },
    btnLoadingPlan(plan) {
      if (plan.name === 'EXECUTIVE') {
        return this.loadingPlan.executive;
      } else {
        return this.loading;
      }
    },
    alertMsg() {
      Notification.success('Please contact to our sales team.');
    },
    planBtnName(plan) {
      let planSubscribed = this.plans.find((plan) => {
        return this.currentSubscription && plan.stripe_id === this.currentSubscription.stripe_price
      });
      if (planSubscribed) {
        if ((plan.price > planSubscribed.price && plan.package === planSubscribed.package)) {
          return 'Upgrade';
        } else if (plan.price === planSubscribed.price && plan.package === planSubscribed.package) {
          return 'Subscribed';
        }else {
          return 'Downgrade';
        }
      }
      return 'Upgrade';
    },
    addSubscription(plan) {
      this.$emit('addSubscription', plan)
    },
    getSubscriptionValidity() {
      let sub = this.currentSubscription;
      if (sub.ends_at) {
        return `Subscription Ends: ${moment(sub.ends_at).format('MMM D, YYYY')}`;
      } else if (sub && sub.stripe_status === 'trialing') {
        return `Trial Period Ends: ${moment(sub.trial_ends_at).format('MMM D, YYYY')}`;
      }
    },
    cancelPlan() {
      this.$confirm('Do you want to cancel your subscription? Please note that you will be able to still use this application till the last date of the current subscription plan.  After the last date of your subscription, your plan will not be automatically renewed.', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      }).then(() => {
        this.$emit('cancelSubscription');
      })
    },
    resumePlan() {
      this.$confirm('Do you want to resume your subscription?', 'Confrim', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'success'
      }).then(() => {
        this.$emit('resumeSubscription');
      })
    },
  },
  watch: {
    "currentSubscription": {
      handler(sub) {
        // console.log(sub);
        // console.log(this.plans);
      }
    }
  }
}
</script>
<style>
.pricing-upgrade-info .text-sm {
  font-size: .74rem !important;
}
</style>
