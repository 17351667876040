<template>
  <div class="container-fluid p-0">
    <div class="page-headermb-3">
      <h2 class="main-content-title">Pricing Plan</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xxl-12">
            <div>
              <PricingPlans
                  :team-plans="teamPlans"
                  :personal-plans="personalPlans"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingPlans from "../../components/pricing/PricingPlans";
import SavedCreditCards from "../../components/pricing/SavedCreditCards";
import axios from "axios";

export default {
  name: "Index",
  components: {
    SavedCreditCards,
    PricingPlans,
  },
  data() {
    return {
      personalPlans: [],
      teamPlans: [],
      openPlanMenu: false,
    };
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    getPlans() {
      axios.get('/subscriptions/pricing')
          .then(response => {
            this.personalPlans = response.data.personal;
            this.teamPlans = response.data.team;
          })
    },
  }
};
</script>