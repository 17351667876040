<template>
  <div class="princing-content">
    <h3 class="card-title mb-3 text-center">{{ isTeam ? 'Team' : 'Personal' }} Pricing Plans</h3>
    <div class="text-center">
      <el-radio-group class="mb-3 switch-btns" v-model="planType">
        <el-radio-button label="Monthly"></el-radio-button>
        <el-radio-button label="Yearly"></el-radio-button>
      </el-radio-group>
    </div>
    <div v-if="isTeam" class="text-center">
      <el-select class="mb-4" v-model="team" :placeholder="teams.length > 0 ? 'Select a Team' : 'No Team'">
        <el-option
            v-for="item in teams"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="pricing-block-area">
      <div v-if="!isTeam || team !== null">
        <div class="text-center" v-if="loadingCurrentSubscription">
          <i class="el-icon-loading"></i>
        </div>
        <pricing-plan
            v-else
            :currentSubscription="currentSubscription"
            :plans="planType === 'Monthly' ? plans.monthly : plans.yearly"
            @addSubscription="addSubscription($event)"
            @cancelSubscription="cancelSubscription"
            @resumeSubscription="resumeSubscription"
            :loading="loading"
            :loadingPlan="loadingPlan"
        />
      </div>
    </div>

    <select-card-modal
        :show="showCardModal"
        :upgradeOrDowngrade="upgradeOrDowngrade"
        :all-card="paymentMethods"
        :plan="selectPlan"
        :team-id="team"
        :membersCount="membersCount"
        @addNewCard="addNewCard"
        @close="showCardModal = false"
        @success="subscribeSuccess"
    />
    <add-new-card-modal
        :show="showAddNewCardModal"
        @close="showAddNewCardModal = false"
    />

    <message-modal
        :show="showMessageModal"
        @close="showMessageModalClose"
    />

  </div>
</template>

<script>
import axios from "axios";
import AddNewCardModal from "@/components/pricing/AddNewCardModal";
import PricingPlan from "../../components/pricing/PricingPlan";
import SelectCardModal from "@/components/pricing/SelectCardModal.vue";
import {Notification} from "element-ui";
import MessageModal from "./MessageModal";
import * as url from "url";

export default {
  name: "PricingPlans",
  components: {PricingPlan, SelectCardModal, AddNewCardModal, MessageModal},
  props: ['teamPlans', 'personalPlans'],
  data() {
    return {
      formData: {},
      rules: {},
      planType: 'Monthly',
      teams: [],
      team: null,
      loading: false,
      showAddNewCardModal: false,
      showCardModal: false,
      paymentMethods: [],
      selectPlan: {},
      loadingCurrentSubscription: false,
      currentSubscription: null,
      upgradeOrDowngrade: "downgrade",
      showMessageModal: false,
      loadingPlan: {
        plus: false,
        premium: false,
        executive: false
      }
    }
  },
  computed: {
    teamId() {
      if (this.team) {
        this.checkSubscription(this.team)
      } else {
        this.checkSubscription();
      }
      return this.team;
    },
    isTeam() {
      return this.$route.query.submenu === 'team';
    },
    membersCount(){
      if(this.teamId){
        let teamObj = this.teams.find((obj) => {
          return obj['id'] === this.teamId;
        })
        if(teamObj){
          return teamObj.users.length
        }
      }
      return 1;
    },
    plans() {
      if (this.isTeam) {
        return this.teamPlans;
      }
      return this.personalPlans
    }
  },
  mounted() {
    if (this.team) {
      this.checkSubscription(this.team);
    } else {
      this.checkSubscription();
    }
  },
  methods: {
    getTeams() {
      axios.get('teams/teams-for-plans')
          .then(res => {
            this.teams = res.data;
            this.$nextTick(() => {
              if (this.$route.query.team) {
                this.team = parseInt(this.$route.query.team)
              }
            })
          })
    },
    showMessageModalClose() {
      this.showMessageModal = false;
      this.callToStripPaymentMethod();
    },

    cancelSubscription(){
      axios.post(`/subscriptions/cancel`, { teamId: this.team}).then(() => {
        Notification.success('Subscription has been cancelled successfully.');
        this.$forceUpdate();
        this.subscribeSuccess();
      }).catch((error) => {
        if (error.response.data.errors) {
          Notification.error(Object.values(error.response.data.errors)[0][0]);
        } else {
          Notification.error(error.response.data.message);
        }
      })
    },
    resumeSubscription(){
      axios.post(`/subscriptions/resume`, { teamId: this.team}).then(() => {
        Notification.success('Subscription has been resumed successfully.');
        this.$forceUpdate();
        this.subscribeSuccess();
      }).catch((error) => {
        if (error.response.data.errors) {
          Notification.error(Object.values(error.response.data.errors)[0][0]);
        } else {
          Notification.error(error.response.data.message);
        }
      })
    },
    async addSubscription(plan) {
      this.loading = true;
      this.selectPlan = plan;
        this.callToStripPaymentMethod();
    },
    callToStripPaymentMethod() {
      axios.get("/subscriptions/payment-method")
          .then((response) => {
            this.paymentMethods = response.data;
            this.showCardModal = true;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
            this.loadingPlan = {plus: false, premium: false, executive: false};
          });
    },

    addNewCard() {
      this.showCardModal = false;
      this.showAddNewCardModal = true;
    },

    checkSubscription: function (id) {
      this.loadingCurrentSubscription = true;
      let url = '';
      if (this.isTeam) {
        url = `/subscriptions/subscribed?team=${id}`;
      } else {
        url = `/subscriptions/subscribed`;
      }
      axios.get(url)
          .then(res => {
            this.currentSubscription = res.data.currentSubscription;
          })
          .catch(() => {
            Notification.error("Something went wrong, Please try again later!")
          })
          .finally(() => {
            this.loadingCurrentSubscription = false;
          })
    },

    subscribeSuccess() {
      if(this.team){
        this.checkSubscription(this.team);
      }else{
        this.checkSubscription();
      }
    },


  },
  watch: {
    'team': {
      immediate: true,
      handler(id) {
        if (id) {
          this.checkSubscription(id)
        } else {
          this.checkSubscription()
        }
      }
    },
    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.submenu === 'team') {
          this.getTeams();
        } else {
          this.team = null;
          this.checkSubscription();
        }
        this.selectPlan = null;
      }
    }
  }
}
</script>