<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h3 class="card-title mb-0">Saved Credit Card</h3>
            <el-button @click.prevent="showCard = true" type="success" class="btn btn-sm btn-success">
                Add a New Card
            </el-button>
        </div>

        <div class="table-responsive">
            <el-table
                :data="paymentMethods"
                v-loading="loading"
                style="width: 100%">
                <el-table-column label="Card Holder Name">
                    <template slot-scope="scope">{{ scope.row.billing_details.name }}</template>
                </el-table-column>
                <el-table-column align="center" label="Last Four Digits">
                    <template slot-scope="scope">{{ scope.row.card.last4 }}</template>
                </el-table-column>

                <el-table-column align="center" fixed="right" label="Action">
                    <template slot-scope="scope">

                        <el-button
                            @click="handleEdit(scope.row)"
                            type="primary"
                            icon="el-icon-edit"
                            class="small-icon-btn"
                        />

                        <el-popconfirm
                            class="ms-2"
                            confirm-button-text='OK'
                            cancel-button-text='Cancel'
                            icon="el-icon-info"
                            icon-color="red"
                            title="Do you want to remove credit card information?"
                            @confirm="handleRemove(scope.row.id)"
                        >

                            <el-button
                                slot="reference"
                                type="danger"
                                icon="el-icon-delete"
                                class="small-icon-btn"
                            />
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <add-new-card-modal
            :show="showCard"
            @close="handleClose"
        />

        <edit-card-modal
            :show="showEditCard"
            @close="handleClose"
            @success="handleEditSuccess"
            :name="paymentMethod.billing_details.name"
        />
    </div>
</template>

<script>
import AddNewCardModal from "../../components/pricing/AddNewCardModal";
import {Notification} from "element-ui";
import axios from "axios";
import EditCardModal from "./EditCardModal";

export default {
    name: "SavedCreditCards",
    components: {EditCardModal, AddNewCardModal},
    data() {
        return {
            showCard: false,
            showEditCard: false,
            paymentMethods: [],
            paymentMethod: {
                billing_details: {
                    name: "",
                },
            },
            loading: false,
        };
    },
    mounted() {
        this.allPaymentMethods();
    },
    methods: {
        allPaymentMethods() {
            this.loading = true;
            axios.get("/subscriptions/payment-method")
                .then((response) => {
                    this.paymentMethods = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleRemove(paymentMethodId) {
            this.loading = true;
            axios.delete('/subscriptions/payment-method/' + paymentMethodId)
                .then(() => {
                    this.allPaymentMethods();
                    if (this.showEditCard) {
                        this.showEditCard = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleEdit(card) {
            this.paymentMethod = card;
            this.showEditCard = true;
            this.$forceUpdate();
            console.log('card', card)
        },

        handleClose() {
            this.showCard = false;
            this.showEditCard = false;
        },

        handleEditSuccess() {
            this.handleRemove(this.paymentMethod.id);
        }
    },
    watch: {
        'showCard': {
            handler(value) {
                if (!value) {
                    this.allPaymentMethods();
                }
            }
        }
    }
};
</script>

<style scoped>
.sidebar-title {
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    color: #092c4c;
}
</style>