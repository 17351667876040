<template>
    <el-dialog
        :visible.sync="show"
        :before-close="handleClose"
        class="project-modal small-modal"
    >
        <div>
            <h3 class="modal-title">Add a New Card</h3>
            <div>
                <input v-model="cardHolderName" id="card-holder-name" type="text" placeholder="Card Holder Name" class="custom-input-class"/>
                <!-- Stripe Elements Placeholder -->
                <div class="custom-input-class" id="card-element"></div>
                <p class="error" v-if="errors.cards">{{ errors.cards }}</p>
                <div class="d-flex justify-content-end">
                    <el-button
                        type="button"
                        @click.prevent="$emit('close')">Close
                    </el-button>
                    <el-button
                        :loading="loading"
                        type="success"
                        @click="submit('form')">Save Card
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<style scoped>
.error {
    color:red !important;
}
</style>
<script>
import {loadStripe} from "@stripe/stripe-js";
import {Notification} from "element-ui";
import axios from "axios";

export default {
    name: "AddNewCardModal",
    props: ["show"],
    data() {
        return {
            stripe: {},
            cardElement: {},
            cardHolderName: "",
            intent: null,
            loading: false,
            errors: {
                cards: '',
            }
        };
    },

    created() {
        axios.get("/stripe-payment-method/intent")
        .then((response) => {
            this.intent = response.data;
        })
        .error((error)=>{
        });
    },

    methods: {
        handleClose() {
            this.$emit('close');
        },

        async submit() {
            this.loading = true;
            const {setupIntent, error} = await this.stripe.confirmCardSetup(
                this.intent.client_secret,
                {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {name: this.cardHolderName},
                    },
                }
            );

            if (error) {
                this.errors.cards = error.message;
                this.loading= false;
            } else {
                axios.post("/subscriptions/payment-method", {
                    payment_method: setupIntent.payment_method,
                })
                .then(() => {
                    Notification.success("Payment method added successfully");
                    this.$emit("close");
                    this.$emit('success');
                })
                .catch((error) => {
                    console.log(error);
                    this.errors.cards = error.message;
                    this.loading= false;
                })
                .finally(() => {
                    this.loading = false;
                })
            }
        },
    },
    watch: {
        'show': {
            handler(value) {
                if (value) {
                    this.loading = true;
                    loadStripe(process.env.VUE_APP_STRIPE_KEY)
                        .then(res => {
                            this.stripe = res;
                            const elements = this.stripe.elements();
                            this.cardElement = elements.create("card");
                            this.cardElement.mount("#card-element");
                            this.loading = false;
                        })
                    axios.get("/stripe-payment-method/intent").then((response) => {
                        this.intent = response.data;
                    });
                }
            },
            immediate: true
        },

    }
};
</script>