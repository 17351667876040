<template>
    <el-dialog
        :visible.sync="show"
        :before-close="handleClose"
        class="project-modal small-modal"
    >
        <div>
            <h3 class="modal-title">Update Card Informations</h3>
            <div>
                <input
                    v-model="cardHolderName"
                    id="card-holder-name"
                    type="text"
                    placeholder="card holder name"
                    class="custom-input-class"
                />
                <!-- Stripe Elements Placeholder -->
                <div class="custom-input-class" id="stripe-card-element"></div>
                <div class="d-flex justify-content-end">
                    <el-button
                        type="button"
                        @click.prevent="$emit('close')">Close
                    </el-button>
                    <el-button
                        :loading="loading"
                        type="success"
                        @click="submit('form')">Update Card
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>


<script>
import {loadStripe} from "@stripe/stripe-js";
import {Notification} from "element-ui";
import axios from "axios";

export default {
    name: "EditCardModal",
    props: ["show", "name"],
    data() {
        return {
            stripe: {},
            cardElement: {},
            cardHolderName: "",
            intent: null,
            loading: false,
        };
    },
    created() {
        axios.get("/subscriptions/payment-method/intent").then((response) => {
            this.intent = response.data;
        });
    },

    methods: {
        handleClose() {
            this.$emit('close');
        },

        async submit() {
            this.loading = true;
            const {setupIntent, error} = await this.stripe.confirmCardSetup(
                this.intent.client_secret,
                {
                    payment_method: {
                        card: this.cardElement,
                        billing_details: {name: this.cardHolderName},
                    },
                }
            );

            if (error) {
                console.log(error.message);
                // Display "error.message" to the user...
            } else {
                axios.post("/subscriptions/payment-method", {
                    payment_method: setupIntent.payment_method,
                })
                    .then(() => {
                        // Notification.success("Payment method added successfully");
                        this.$emit("success");
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    })
                // The card has been verified successfully...
            }
        },
    },
    watch: {
        'show': {
            handler(value) {
                if (value) {
                    this.loading = true;
                    loadStripe(process.env.VUE_APP_STRIPE_KEY)
                        .then(res => {
                            this.stripe = res;
                            const elements = this.stripe.elements();
                            this.cardElement = elements.create("card");
                            this.cardElement.mount("#stripe-card-element");
                            this.loading = false;
                        })
                    axios.get("/subscriptions/payment-method/intent").then((response) => {
                        this.intent = response.data;
                        
                    });

                }
            },
            immediate: true
        },
        "name": {
            handler(value) {
                this.cardHolderName = value;
            },
            immediate: true
        },

    }
};
</script>