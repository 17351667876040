<template>
    <div>
        <el-dialog
            :visible="show"
            :before-close="handleClose"
            width="390px"
            class="project-modal"
        >
            <p>
              You may need to delete some active projects <br> once your new
              pricing plan is activated
            </p>
            <div class="d-flex justify-content-end">
                <el-button
                    type="text"
                    @click.prevent="handleClose()">CLOSE
                </el-button>
            </div>
        </el-dialog>

    </div>
</template>


<script>
export default {
    name: "MessageModal",
    props: ["show"],
    methods: {
        handleClose() {
            this.$emit('close', false);
        }
    }
};
</script>

<style scoped>
h3 {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #092c4c;
    margin-bottom: 30px;
}

.custom-input-class {
    width: 100%;
    background: #F3F3F3;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 25px;
    font-size: 14px;
}
</style>