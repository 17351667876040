<template>
    <el-dialog
        :visible.sync="show"
        width="390px"
        class="project-modal"
    >
        <div>
            <h3>Payment Summary</h3>
            <div>
                <h6>Apply Coupon</h6>
                <div>
                    <el-input placeholder="Please input coupon" v-model="coupon" class="input-with-select mb-3">
                        <el-button :disabled="!coupon" @click.prevent="checkCoupon" slot="append">Apply</el-button>
                    </el-input>
                </div>
            </div>
            <div class="payment-summary-details">
                <div class="title p-2">{{ plan.name }}</div>
                <div class="d-flex justify-content-between p-2">
                    <div>Direct Charge</div>
                    <div>
                        <span v-if="plan.package === 'yearly'">${{
                                plan.price
                            }} x 12 = ${{ directAmount }}</span>
                        <span v-else>${{ directAmount }}</span>
                    </div>
                </div>
              <div v-if="teamId && membersCount" class="d-flex justify-content-between p-2">
                <div>Total Team Members</div>
                <div>x{{ membersCount }}</div>
              </div>
                <div class="d-flex justify-content-between p-2">
                    <div>Sales Tax</div>
                    <div>$0</div>
                </div>
                <div v-if="amount_off !== null || percent_off !== null" class="d-flex justify-content-between p-2">
                    <div>Discount</div>
                    <div v-if="percent_off !== null">{{ percent_off }}%</div>
                    <div v-if="amount_off !== null">${{ amount_off }}</div>
                </div>

                <div class="d-flex justify-content-between p-2 border-top mt-5">
                    <div>Total Charge on Credit Card</div>
                    <div>
                        <strong>${{ totalAmount(plan) }}</strong>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-end mt-5">
                <el-button
                    type="text"
                    @click="handleClose">Close
                </el-button>
                <el-button
                    :loading="loading"
                    type="success"
                    @click="subscribePlan">Start Pricing Plan
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";

export default {
    name: "SelectCreditCard",
    props: ["show", "plan", "card", "upgradeOrDowngrade", "teamId", "membersCount"],
    data() {
        return {
            loading: false,
            coupon: "",
            percent_off: null,
            amount_off: null,
        }
    },
    methods: {
        checkCoupon() {
            axios.get('subscriptions/coupons/' + this.coupon)
                .then(res => {
                    this.percent_off = res.data.percent_off;
                    this.amount_off = res.data.amount_off;
                    this.coupon = res.data.id;
                })
                .catch(() => {
                    Notification.error('Coupon not found.');
                })
        },
        totalAmount(plan) {
            let total = this.teamId ? this.directAmount * this.membersCount : this.directAmount;
            if (this.amount_off !== null) {
                total -= this.amount_off;
            } else if (this.percent_off !== null) {
                total -= total * this.percent_off / 100;
            }
            return total.toFixed(2);
        },
        subscribePlan() {
            this.loading = true;
            axios.post( '/subscriptions/subscribe', {
                'paymentMethodId': this.card,
                'planId': this.plan.stripe_id,
                'plan': this.plan,
                'teamId': this.teamId,
                'coupon': this.coupon,
                'userTimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            }).then(res => {
                this.$emit('close', false);
                this.$emit('success', res.data);
            }).catch(err => {
              let response  = err.response;
              if(response.status === 403)
                if(response.data.status === false){
                  response.data.messages.forEach( function (message){
                    setTimeout(function() {
                      Notification.error(message);
                    },  600);
                  });
                }else if(response.data.error){
                  Notification.error(response.data.error);
                }
              if(response.status === 500){
                  Notification.error(response.data.message);
              }
            }).finally(() => {
                this.loading = false
            })
        },

        handleClose() {
            this.$emit('close', false);
        }
    },
    computed:{
      directAmount(){
        if(this.plan.package === 'yearly'){
          return parseFloat((this.plan.price * 12).toFixed(2));
        }
        return parseFloat(this.plan.price);
      }
    }
}
</script>

<style scoped lang="scss">
h3 {
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #092C4C;
    margin-bottom: 30px;
}

.payment-summary-details {
    border: 1px solid #dddddd;

    .title {
        border-bottom: 1px solid #dddddd;
        font-weight: 700;
        font-size: 16px;
        color: #092C4C;
    }

}
</style>